<template>
    <div class="page">
        <div class="header">
            <div class="btns">
                <el-button size="mini" @click="handleAdd" icon="el-icon-plus" type="defalut" v-has="'model_add'">新建</el-button>
                <el-button size="mini" @click="getData" icon="el-icon-refresh">刷新</el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="干预模板" />
        </div>
        <div class="search">
            <el-form :inline="true" :model="search" size="small">
                <el-form-item label="模板名称">
                    <el-input v-model="search.name" placeholder="请输入" clearable />
                </el-form-item>
                <el-form-item label="创建人">
                    <el-input v-model="search.createBy" placeholder="请输入" clearable />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="getData" v-has="'model_search'">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="main-content">
            <div class="table-content">
                <el-table :data="table.data" border stripe size="mini" v-loading="loading" width="100%" height="100%"
                    :header-cell-style="{ backgroundColor: '#FAFAFA' }">
                    <el-table-column type="index" label="序号" align="center"
                        :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }" width="55" />
                    <el-table-column prop="name" label="模板名称" show-overflow-tooltip />
                    <el-table-column prop="createTime" label="编辑时间"  show-overflow-tooltip >
                        <template slot-scope="scope">
                            {{scope.row.createTime|datetime}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="createBy" label="创建人" show-overflow-tooltip />
                    <el-table-column align="center" width="120" label="操作" fixed="right">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" @click="handleUpdate(scope.row)" icon="el-icon-edit" v-has="'model_edit'">
                                编辑
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                @current-change="handlePageChange" @size-change="handlePageSizeChange" />
        </div>
        <model-form :visible="dialogVisible" @close="dialogClose" :form="form" @update="getData" ></model-form>
    </div>
</template>

<script>
import ModelForm from "./widgets/modelForm/index";

export default {
    components: {
        ModelForm
    },
    data() {
        return {
            loading: false,
            search: {},
            dialogVisible: false,
            table: {
                data: [],
                total: 0,
                page: 1,
                pageSize: 20
            },
            form:{}

        }
    },
    methods: {
        handleAdd() {
            this.form = {
                plans: [],
                healthGuidance: {},
                interveneModus:"",
                image:[],
                video:[]
            }
            this.dialogVisible = true
        },
        async handleUpdate(row) {
            var that = this
            const resp = await this.$http.get(`/program/view/${row.id}`)
            if (!resp.data.data) {
                that.$message.error(resp.data.msg)
            } else {
                var taskList = []
                resp.data.data.plans.forEach(function (item) {
                    if(item.interveneModus == "3"){
                        if(typeof item.content  === "string") {
                            item.content = JSON.parse(item.content || "{}")
                        }
                        taskList.push(item);
                    }else {
                        taskList.push(item);
                    }
                })
                resp.data.data.plans = taskList
                that.form = resp.data.data
                if(!that.form.image){
                    that.form.image = []
                }
                if(!that.form.video){
                    that.form.video = []
                }
                that.dialogVisible = true
            }
        },
        dialogClose() {
            this.dialogVisible = false
        },
        handlePageChange(e) {
            this.table.page = e
            this.getData()
        },
        handlePageSizeChange(e) {
            this.table.pageSize = e
            this.getData()
        },
        async getData() {
            this.loading = true
            const resp = await this.$http.get(`/program?page=${this.table.page}&pageSize=${this.table.pageSize}&status=1`, { params: this.search })
            if (!resp.data) {
                this.$message.error(resp.data.msg)
            } else {
                this.table.data = resp.data.data
                this.table.total = resp.data.total
            }
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.page {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header {
    padding-bottom: 10px;

    .btns {
        float: right;
    }
}

.search {
    padding: 10px;
    background-color: #FAFAFA;
    border-top: 1px solid #EBEEF5;
    border-left: 1px solid #EBEEF5;
    border-right: 1px solid #EBEEF5;

    ::v-deep .el-form-item {
        padding: 0 !important;
        margin-bottom: 0 !important;
    }
}

.main-content {
    flex: 1;
    position: relative;
}

.table-content {
    position: absolute;
    width: 100%;
    height: 100%;
}

.pager {
    padding-top: 10px;
    text-align: center;
}
</style>
